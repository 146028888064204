/* ----------------------------------------------------------------
	Custom CSS

	Add all your Custom Styled CSS here for New Styles or
	Overwriting Default Theme Styles for Better Hanlding Updates
-----------------------------------------------------------------*/
.h2,
h2 {
  font-size: 1.5rem !important;
}
.btn-primary {
  border-color: #0a332a !important;
}
.dotted-devider {
  width: 100%;
  height: 1px;
  background: transparent;
  border-top: 2px dotted #ececec;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.preloader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.call-now {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  padding: 0;
  background: transparent;
  border: 1px solid linear-gradient(to right, #093028, #237a57);
  color: linear-gradient(to right, #093028, #237a57);
}

.call-now:hover {
  background: linear-gradient(to right, #093028, #237a57);
  color: #fff;
}

.search-container {
  padding-top: 2rem;
  padding-bottom: 4rem;
  background-image: url(../images/Banner/banner1_A.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 550px;
}

.search-section {
  position: relative;
  background: transparent;
  padding: 0rem;
  box-shadow: none;
}

.search-flight {
  position: relative;
  margin-top: 2rem;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  left: 50%;
  padding: 0;
  transform: translate(-50%);
}
.search-flight2 {
  position: relative;
  border-radius: 0;
  padding: 1rem 2rem;
  width: 100%;
  margin-top: 2rem;
}

.search-control {
  margin-bottom: 0.25rem;
}

.search-control .form-control {
  height: 60px;
  border: 0;
  border-radius: 0;
  display: flex;
  align-items: center;
}

.btn-swap {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  left: auto;
  right: 0.5rem;
  top: calc(50% - 4px);
  transform: translate(0, -50%) rotate(90deg);
  background: #fff;
  padding: 0 !important;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
/* for index 3............................................................ */
.overlay3 {
  background: rgba(0, 0, 0, 0.1);
}
.header-overlay {
  background: rgba(14, 88, 207, 0.6);
}
.flex-1 > div,
.flex1 > div {
  flex: 1;
}

.flex-1 > div {
  margin-right: 0;
}

.trip-rype label {
  color: #fff;
}

.floating-call {
  position: fixed;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  right: 1rem;
  bottom: 2rem;
  border: 0;
  background: linear-gradient(to right, #093028, #237a57);
  color: #fff;
  overflow: hidden;
  z-index: 9999;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  box-shadow: 2px 2px 6px rgba(14, 88, 207, 0.6);
}
.floating-call:hover {
  background: linear-gradient(to right, #093028, #237a57);
  box-shadow: 2px 2px 15px rgba(14, 88, 207, 0.6);
}
.floating-call span {
  display: none;
}

.flight-List {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  bottom: 0;
  -webkit-box-shadow: 0px 2px 12px rgb(0 0 0 / 18%);
  box-shadow: 0px 2px 12px rgb(0 0 0 / 18%);
  z-index: 11;
  background: #fff;
  padding: 1rem;
  min-width: 20rem;
  width: 100%;
}
.flight-List p {
  margin-bottom: 0rem;
  font-size: 0.75rem;
  font-weight: 600;
}
.flight-List hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background: #ccc;
}
.city-name span:first-child {
  font-size: large;
}
.city-name span:last-child {
  font-weight: bold;
}
.airport-name {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 600;
  font-size: 0.75rem;
}
.flightFromList {
  padding: 0.5rem 0;
  border-bottom: 1px solid #ececec;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}
.flightFromList:hover {
  border-bottom: 1px solid linear-gradient(to right, #093028, #237a57);
}

.airport-shortcuts {
  padding: 0.5rem;
  width: 2.75rem;
  color: #fff;
  font-size: 0.75rem;
  text-align: center;
  font-weight: 600;
  line-height: 1;
}

.travellers-dropdown {
  border-radius: 0;
}
.search-control .travellers-dropdown input[type="text"] {
  height: auto;
}

.return-on {
  display: none;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.trips-btn .btn-group {
  width: 100%;
}

.trips-btn .btn-group > .btn:not(:first-child) {
  border-radius: 0;
}

.trips-btn .btn-glass {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  padding: 1rem 1.5rem;
  border: 0;
  width: 50%;
}

.trips-btn .btn-glass:hover,
.trips-btn .btn-glass.focus,
.trips-btn .btn-glass.active,
.trips-btn.btn-glass:active {
  box-shadow: 0;
  background: #fff;
  color: #000;
  color: #333;
  border: 0;
}

/* For index 3.................................................. */
.trips-btn3 .btn-glass {
  background: #fff;
  color: #777;
}

.trips-btn3 .btn-glass:hover,
.trips-btn3 .btn-glass.focus,
.trips-btn3 .btn-glass.active,
.trips-btn3.btn-glass:active {
  background: linear-gradient(to right, #093028, #237a57);
  color: #fff;
}

/* For index 3.................................................. */

.trips-btn .btn-group > .btn:not(:first-child) {
  margin-left: 0px;
}

.trips-btn label {
  margin-bottom: 0px;
}
.search-list-heading {
  font-weight: 600;
  font-size: small;
}
.placeholder {
  opacity: 0.8;
}
.filled-value {
  font-weight: 600;
  color: #333;
}
/* Toggle Switch...................................... */
.switch {
  position: relative;
  display: inline-block;
  width: 150px;
  height: 40px;
  margin-bottom: 0.25rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 32px;
  left: 4px;
  bottom: 4px;
  background: linear-gradient(to right, #093028, #237a57);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background: linear-gradient(to right, #093028, #237a57);
}
input:checked + .slider::before {
  background-color: #fff;
}
/* input:focus + .slider {
	box-shadow: 0 0 1px linear-gradient(to right, #093028, #237a57);;
  } */

input:checked + .slider:before {
  -webkit-transform: translateX(110px);
  -ms-transform: translateX(110px);
  transform: translateX(110px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.switch input + .slider + .labeled::before {
  display: inline-block;
  position: absolute;
  content: "One Way";
  color: linear-gradient(to right, #093028, #237a57);
  left: auto;
  right: 1rem;
  top: 0.6rem;
  font-size: 1rem;
  font-weight: 600;
}
.switch input:checked + .slider + .labeled::before {
  display: inline-block;
  position: absolute;
  content: "Round Trip";
  color: #fff;
  left: 1rem;
  top: 0.6rem;
  font-size: 1rem;
  font-weight: 600;
}
#bookingFlight input[type="text"]:focus {
  box-shadow: none;
}

/* search-result css........................................... */
.filters-search-mobile .btn-group > .btn {
  border-radius: 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  background: #fff;
}
.company-info img {
  width: 2rem;
}
.search-result .research-flight {
  border-radius: 0;
}
.search-summary .result {
  font-weight: 600;
}
#preloader.search-preloader {
  position: relative;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  z-index: auto !important;
  background: transparent;
  display: block;
  margin-bottom: 3rem;
  height: auto;
}
.search-summary {
  opacity: 0.7;
  margin-bottom: 0rem;
}
#preloader.search-preloader [data-loader="dual-ring"] {
  position: relative;
  margin-top: 0;
  margin-bottom: 1rem;
  top: 0;
}
.where-to-from-summary {
  opacity: 1;
}
.flight-time-in-out,
.price-book,
.company-info {
  width: 100%;
}
.price-book {
  background: #ccc;
}
.flight-details-link {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.time-info {
  position: relative;
}
.total-time,
.stop {
  position: relative;
}
.stop-icon {
  position: relative;
}
.stop-icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #777;
  z-index: 1;
}
.stop-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #777;
}
.stop {
  padding-bottom: 0.75rem;
}
.total-time {
  padding-top: 0.75rem;
}
.devider {
  position: relative;
}

.devider::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #ccc;
  z-index: 0;
  top: 50%;
  left: 0;
}
.confirm-detail-header {
  background: #ececec;
}
.confirm-flight-summary .stop,
.confirm-flight-summary .total-time {
  padding: 0;
}
.page-header {
  margin: 0;
}

.show-on-mobile {
  display: none;
}
.flight-item,
.confirm-flight-summary {
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
  transition: all ease-in-out 0.3s;
}
.flight-item:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.2);
}
/* search-result end here css........................................... */

/* Toggle Switch end here...................................... */

@media (max-width: 767px) {
  /* .daterangepicker {
		top: 0 !important;
		left: 0 !important;
		right: 0;
		bottom: 0;
		width: 100%;
		margin-top: 0 !important;
	}
	.daterangepicker .drp-calendar {
		max-width: 100% !important;
		width: 100%;
	} */
  .travellers-dropdown {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}

@media (min-width: 768px) {
  .search-container {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
  .trips-btn .btn-group {
    width: auto;
  }
  .trips-btn .btn-glass {
    width: auto;
  }
  .glass-bg-shadow {
    box-shadow: 2px -6px 6px rgba(0, 0, 0, 0.3);
  }

  .search-section {
    position: relative;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
  }

  .form-control.to {
    padding-left: 2rem;
  }
  .form-control.from {
    padding-right: 2rem;
  }

  .flight-List {
    position: absolute;
    left: 0;
    top: auto;
    bottom: auto;
    margin-bottom: 1rem;
  }

  .search-flight2 {
    position: absolute;
    bottom: -40px;
    left: 50%;
    width: auto;
    height: auto;
    border-radius: 0;
    padding: 1rem 2rem;
    transform: translate(-50%);
  }
  .search-flight {
    position: absolute;
    bottom: -40px;
    left: 50%;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 0;
    transform: translate(-50%);
  }
  .btn-swap {
    left: 50%;
    right: auto;
    top: calc(50% - 4px);
    transform: translate(-50%, -50%);
  }

  .bdr-light-left {
    border-left: 1px solid #d4d4d4;
  }

  .bdr-light-right {
    border-right: 1px solid #d4d4d4;
  }

  .floating-call {
    width: auto;
    height: auto;
    border-radius: 0;
    padding: 0.75rem 1.25rem;
  }
  .floating-call span {
    display: inline-block;
  }
  .price-book,
  .company-info {
    width: auto;
  }
  .flight-details-link {
    position: relative;
    top: auto;
    right: auto;
  }
  .one-way-result .flight-time-in-out {
    width: 50%;
  }
  .return-result .flight-time-in-out {
    width: 80%;
  }
  .price-book {
    background: transparent;
  }
  .one-way-result,
  .return-result {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .page-header {
    margin: 0 0 30px 0;
  }
  .show-on-mobile {
    display: block;
  }
}
@media (min-width: 1200px) {
  .travellers-class,
  .dat-selector {
    max-width: 30%;
  }
  .search-control {
    margin-bottom: 0;
  }

  .flex-1 > div:not(:last-child) {
    margin-right: 1rem;
  }
  .btn-swap {
    left: 50%;
    right: auto;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
